import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/Landing.vue";
import About from "../views/About.vue";
import SubPages from "../views/SubPages.vue";
import Policy from "../views/Policy.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
    props: { id: 0 },
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/wireless",
    name: "Wireless",
    component: SubPages,
  },
  {
    path: "/installations",
    name: "Installations",
    component: SubPages,
  },
  {
    path: "/onSiteSupport",
    name: "OnSiteSupport",
    component: SubPages,
  },
  {
    path: "/deployments",
    name: "Deployments",
    component: SubPages,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Policy,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
