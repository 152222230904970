<template>
  <v-app>
    <!-- APPBAR -->
    <v-app-bar app>
      <v-img
        src="./assets/logo/houseit_logo.png"
        max-height="50"
        max-width="180"
        contain
        @click="goToPage('/')"
        style="cursor: pointer"
      />
      <v-spacer />
      <v-toolbar-items class="hidden-md-and-down">
        <v-btn
          v-for="(btn, i) in menuButtons"
          :key="i"
          class="pa-2"
          text
          @click="btn.action()"
        >
          {{ $t(btn.text) }}
          <v-icon v-show="btn.icon">{{ btn.icon }}</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-lg-and-up">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-menu</v-icon>
          </template>
          <v-list>
            <v-list-item v-for="(btn, i) in menuButtons" :key="i">
              <v-btn text width="100%" @click="btn.action()">
                {{ $t(btn.text) }}
                <v-icon v-show="btn.icon">{{ btn.icon }}</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer
      color="grey darken-3"
      dark
      no-gutters
      :height="footerHeight"
      :max-height="footerHeight"
      :key="footerKey"
    >
      <v-container class="pb-0" fluid>
        <v-row justify="center" class="mb-6">
          <v-col cols="12" md="4" lg="3">
            <v-img
              src="./assets/logo/houseit_logo.png"
              max-height="50"
              max-width="180"
              contain
              class="ma-2"
            />
            <p class="ma-2">{{ $t("footerText") }}</p>
          </v-col>

          <v-col cols="12" md="4" lg="3">
            <v-form v-model="valid" ref="form">
              <v-container>
                <div class="text-h5" v-text="$t('contactUs')"></div>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  type="email"
                />
                <v-textarea
                  v-model="message"
                  :label="$t('message')"
                  :rules="messageRules"
                  required
                  scrollable
                />
                <v-row>
                  <v-spacer />
                  <v-btn color="white" text @click="sendMessage()">
                    {{ $t("send") }}
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-col>

          <v-col cols="12" md="4" lg="3" class="pt-14">
            <v-row v-for="info in 3" :key="info">
              <v-col class="ma-auto">
                <v-icon>{{ $t(`footerInfo[${info - 1}].icon`) }}</v-icon>
                {{ $t(`footerInfo[${info - 1}].text`) }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" no-gutters>
          <div class="text-caption grey--text text--darken-1">Page made by Leon Czajka | © 2021 House IT</div>
          <v-btn text class="grey--text" @click="goToPage('/privacy')">{{ $t('policyBtn') }}</v-btn>
        </v-row>
      </v-container>
    </v-footer>

    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarCookies" color="info" timeout="-1">
      {{ $t('cookiesInfo') }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="goToPrivacy()">
          {{ $t('policyBtn') }}
        </v-btn>
        <v-btn color="primary" text v-bind="attrs" @click="snackbarCookies = false; $cookies.set('accepted', true)">
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  name: "App",
  created() {
    document.title = "House IT - Outsourcing IT"
  },
  mounted() {
    document.addEventListener("scroll", () => {
      if (this.ignoreFirstScroll) {
        this.ignoreFirstScroll = false;
      } else if (this.waitForScroll) {
        this.$vuetify.goTo(0).then(() => {
          this.footerHeight = "auto";
          this.footerKey += 1;
        });
        this.waitForScroll = false;
      }
    });

    if (!this.$cookies.get("accepted")) {
      this.snackbarCookies = true
    }
  },

  data() {
    return {
      valid: false,
      message: "",
      messageRules: [(v) => !!v || this.$t("messageRequired")],
      email: "",
      emailRules: [
        (v) => !!v || this.$t("emailRequired"),
        (v) => /.+@.+/.test(v) || this.$t("emailValid"),
      ],
      footerHeight: "auto",
      footerKey: 0,
      waitForScrollFinish: false,
      waitForScrollUp: false,
      ignoreFirstScroll: false,

      snackbar: false,
      snackbarColor: "info",
      snackbarMessage: "",
      snackbarCookies: false,

      menuButtons: [
        {
          action: () => {
            this.goToPage("/");
          },
          text: "homeBtn",
        },
        {
          action: () => {
            this.goToPage("/wireless");
          },
          text: "wirelessBtn",
        },
        {
          action: () => {
            this.goToPage("/installations");
          },
          text: "installationsBtn",
        },
        {
          action: () => {
            this.goToPage("/deployments");
          },
          text: "deploymentsBtn",
        },
        {
          action: () => {
            this.goToPage("/onSiteSupport");
          },
          text: "onSiteSupportBtn",
        },
        {
          action: () => {
            this.goToFooter(this.windowHeight);
          },
          text: "contactBtn",
        },
        {
          action: () => {
            this.goToPage("/about");
          },
          text: "aboutBtn",
        },
        {
          action: () => {
            this.$i18n.locale = this.$t("lang");
          },
          text: "lang",
          icon: "mdi-earth",
        },
      ],
    };
  },
  computed: {
    pageHeight() {
      return document.body.scrollHeight;
    },
    windowHeight() {
      return window.innerHeight;
    },
  },
  methods: {
    goToFooter(height) {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        this.$vuetify.goTo(this.pageHeight);
      } else {
        this.footerHeight = height;
        this.$vuetify.goTo(this.pageHeight).then(() => {
          this.ignoreFirstScroll = true;
          this.waitForScroll = true;
        });
      }
    },
    goToPage(page) {
      if (this.$route.path != page) {
        this.$vuetify.goTo(0, { duration: 0 }).then(() => {
          this.$router.push(page);
        });
      } else {
        this.$vuetify.goTo(0, { duration: 1000})
      } 
    },
    goToPrivacy() {
      this.snackbarCookies = false
      this.$cookies.set('accepted', true)
      this.goToPage('/privacy')
    },
    sendMessage() {
      if (!this.$refs.form.validate()) return;

      this.axios
        .post(
          "/form.php",
          JSON.stringify({
            email: this.email,
            message: this.message,
          })
        )
        .then((res) => {
          if (res.data == 'success') {
            this.snackbarColor = 'success'
            this.snackbarMessage = 'Message sent. We\'ll replay to you as soon as possible.'
            this.snackbar = true
          } else {
            this.snackbarColor = 'error'
            this.snackbarMessage = 'Internal error. Please try again later.'
            this.snackbar = true
          }
        })
        .catch(() => {
          this.snackbarColor = 'error'
          this.snackbarMessage = 'Internal error. Please try again later.'
          this.snackbar = true
        })
    },
  },
};
</script>

<style>
html,
body {
  overflow-x: hidden;
}
</style>
