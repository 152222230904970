<template>
  <v-container fluid class="mb-8">
    <v-container v-for="(contentBlock, i) in contentList" :key="i" fluid>
      <div class="mt-10 text-center text-h3">{{ $t(contentBlock.header) }}</div>
      <v-divider class="ma-3" />
      <v-row
        justify="center"
        class="mt-8"
        v-for="(content, j) in contentBlock.content"
        :key="j"
      >
        <v-col
          cols="12"
          sm="6"
          md="5"
          lg="4"
          :order-md="(j + contentBlock.reversed) % 2 ? 12 : 1"
        >
          <v-img :src="content.image" />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="5"
          lg="4"
          class="text-center pa-6"
          :order-md="(j + contentBlock.reversed) % 2 ? 1 : 12"
        >
          <div class="text-h6">{{ $t(content.title) }}</div>
          <v-divider class="ma-2" />
          <p>{{ $t(content.text) }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "SubPages",

  mounted() {
    this.setContentFromName(this.$route.name);
  },
  watch: {
    $route() {
      this.setContentFromName(this.$route.name);
    },
  },

  data() {
    return {
      staticContent: [
        [
          [
            require("../assets/wireless/ekahau_stock.png"),
            require("../assets/wireless/ekahau_backpack.jpg"),
            require("../assets/wireless/ekahau_box.jpg"),
            require("../assets/wireless/ekahau_close_look.jpg"),
          ],
          [require("../assets/wireless/consulting1.jpg")],
          [require("../assets/wireless/ap1.jpg")],
        ],
        [
          [require("../assets/installations/building.jpg")],
          [
            require("../assets/installations/cabling.jpg"),
            require("../assets/installations/switches.jpg"),
            require("../assets/installations/cctv.jpg"),
          ],
        ],
        [[require("../assets/deployments/server_room.jpg")]],
        [
          [
            require("../assets/onsitesupport/support.jpg"),
            require("../assets/onsitesupport/computer.jpg"),
            require("../assets/onsitesupport/meeting.jpg"),
          ],
        ],
      ],
      contentList: [],
    };
  },

  methods: {
    setContentFromName(routeName) {
      switch (routeName) {
        case "Wireless":
          this.setContent(0);
          break;
        case "Installations":
          this.setContent(1);
          break;
        case "Deployments":
          this.setContent(2);
          break;
        case "OnSiteSupport":
          this.setContent(3);
          break;
        default:
          break;
      }
    },
    setContent(id) {
      this.contentList = [];
      for (const [i, images] of this.staticContent[id].entries()) {
        let contentBlock = {
          header: `pagesContent[${id}][${i}].header`,
          content: [],
        };
        if (this.contentList.length > 0) {
          if (this.contentList[i - 1].content.length % 2 == 1) {
            if (this.contentList[i - 1].reversed) {
              contentBlock = { ...contentBlock, ...{ reversed: 0 } };
            } else {
              contentBlock = { ...contentBlock, ...{ reversed: 1 } };
            }
          }
        } else {
          contentBlock = { ...contentBlock, ...{ reversed: 0 } };
        }
        for (const [j, image] of images.entries()) {
          contentBlock.content.push({
            title: `pagesContent[${id}][${i}].content[${j}].title`,
            text: `pagesContent[${id}][${i}].content[${j}].text`,
            image,
          });
        }
        this.contentList.push(contentBlock);
      }
    },
  },
};
</script>
