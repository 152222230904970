<template>
  <v-container fluid class="ma-0 pa-0">
    <v-container fluid class="ma-0 pa-0" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'landingContainer' : ''">
    <v-img class="landingImg" src="../assets/landing/itwsparcie.jpg">
      <video autoplay playsinline muted :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'landingVideo' : 'landingVideoSmall'">
        <source src="../assets/landing/itwsparcie.mp4" type="video/mp4" />
      </video>
    </v-img>

    <v-row class="pa-4 ma-0" justify="center">
      <v-col cols="12" md="3" v-for="i in 3" :key="i">
        <v-card elevation="0">
          <v-row justify="center" class="ma-4">
            <v-icon x-large>{{ $t(`card[${i - 1}].icon`) }}</v-icon>
          </v-row>
          <v-card-title
            style="word-break: break-word"
            class="justify-center text-center"
            >{{ $t(`card[${i - 1}].title`) }}</v-card-title
          >
          <v-card-text class="text-center">
            {{ $t(`card[${i - 1}].desc`) }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    </v-container>

    <v-container
      v-for="(subPage, i) in subPagesStatic"
      :key="i"
      fluid
      :class="i % 2 ? '' : 'ekahau-bg'"
      class="pa-0 ma-0"
    >
      <v-row justify="center" align="center" class="pa-4 pb-12 pt-12">
        <v-col cols="12" sm="6" md="5" lg="4" :order-sm="i % 2 ? 12 : 0">
          <v-container
            class="ma-0"
            :class="i % 2 ? 'text-center' : 'text-center white--text'"
          >
            <div
              class="text-h4"
              v-text="$t(`landingSubPages[${i}].header`)"
            ></div>
            <p class="mt-4">{{ $t(`landingSubPages[${i}].text`) }}</p>
            <v-row class="pt-8" justify="center">
              <v-btn
                text
                :class="i % 2 ? '' : 'white--text'"
                @click="goToPage(subPage.link)"
              >
                {{ $t("showMore") }}
                <v-icon class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-col>

        <v-col align="center" cols="12" sm="6" md="5" lg="4" :order-sm="i % 2 ? 0 : 12">
            <v-img
              :src="subPage.image"
              max-width="500"
              justify="center"
            ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Landing",

  data() {
    return {
      test: "test",
      subPagesStatic: [
        {
          image: require("../assets/landing/ekahau_laptop_3.jpg"),
          link: "/wireless",
        },
        {
          image: require("../assets/installations/cctv.jpg"),
          link: "/installations",
        },
        {
          image: require("../assets/installations/cabling.jpg"),
          link: "/deployments",
        },
        {
          image: require("../assets/onsitesupport/support.jpg"),
          link: "/onsitesupport",
        },
      ],
    };
  },
  methods: {
    goToPage(page) {
      this.$vuetify.goTo(0, { duration: 0 }).then(() => {
        this.$router.push(page);
      });
    },
  },
};
</script>

<style scoped>
.ekahau-bg {
  background: url("../assets/landing/ekahau-background.png");
}

.landingContainer {
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

.landingimg {
  width: 100%;
  height: 100%;
  position: relative;
}

.landingVideo {
  width: 100%;
  height: calc(100% + 100px);
  object-fit: cover;
  object-position: 50% 50%;
}

.landingVideoSmall {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

</style>
