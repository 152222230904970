import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import axios from "axios";
import VueCookies from "vue-cookies";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(axios)
Vue.use(VueCookies)

Vue.use(VueGtag, {
  config: { id: "G-2XBR583QT2" }
});
new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
